// extracted by mini-css-extract-plugin
export var contacts__awardsCard = "bg_j8";
export var contacts__awardsCardCol = "bg_kc";
export var contacts__awardsCardLogo = "bg_j9";
export var contacts__awardsCardTitle = "bg_kb";
export var contacts__awardsCardWrapper = "bg_j7";
export var contacts__br = "bg_kd";
export var contacts__infoCard = "bg_j4";
export var contacts__infoCardBody = "bg_j6";
export var contacts__infoCardCol = "bg_j3";
export var contacts__infoCardTitle = "bg_j5";
export var contacts__infoCardWrapper = "bg_j2";
export var contacts__withOutBorderBottom = "bg_kf";