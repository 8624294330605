import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import ContactInfo from '~/views/Contacts/components/ContactInfo/ContactInfo'

import * as containerStyles from './ContactsPage.module.scss'

const ContactsPage = () => (
  <MainLayout>
    <section className={containerStyles.contacts}>
      <div className="container container-md">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className={containerStyles.contacts__title}>Contact us</h1>
            <p className={containerStyles.contacts__description}>
              Happy to hear from you! Please drop us a few words about you and
              your project
            </p>
          </div>
        </div>
      </div>
      <ContactFormSection />
      <ContactInfo />
    </section>
  </MainLayout>
)

export default ContactsPage
