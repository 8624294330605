import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { cx, fileToImageLikeData } from '~/utils'

import * as containerStyles from './ContactInfo.module.scss'
import useContactInfoStaticQuery from './useContactInfoStaticQuery'

const ContactInfo = () => {
  const query = useContactInfoStaticQuery()

  const iconClutchDev2020 = getImage(
    fileToImageLikeData(query.iconClutchDev2020),
  )
  const iconClutchDev2021 = getImage(
    fileToImageLikeData(query.iconClutchDev2021),
  )
  const iconClutchDev2023 = getImage(
    fileToImageLikeData(query.iconClutchDev2023),
  )
  const iconClutchGlobal = getImage(fileToImageLikeData(query.iconClutchGlobal))
  const iconClutchChampion = getImage(
    fileToImageLikeData(query.iconClutchChampion),
  )

  const contactAwards = [
    {
      logo: iconClutchDev2020,
      alt: 'Top E-Commerce Developers in Ukraine 2020',
      text: 'Top E-Commerce Developers in Ukraine 2020',
    },
    {
      logo: iconClutchDev2021,
      alt: 'Top Developers in Ukraine 2021',
      text: (
        <>
          <span className={containerStyles.contacts__br}>Top Developers</span>
          <span className={containerStyles.contacts__br}>in Ukraine 2021</span>
        </>
      ),
    },
    {
      logo: iconClutchDev2023,
      alt: 'Top Ruby On Rails Developer in Ukraine 2023',
      text: 'Top Ruby On Rails Developer in Ukraine 2023',
    },
    {
      logo: iconClutchGlobal,
      alt: 'Clutch Global 2023',
      text: (
        <>
          <span className={containerStyles.contacts__br}>Clutch Global</span>
          <span className={containerStyles.contacts__br}>2023</span>
        </>
      ),
    },
    {
      logo: iconClutchChampion,
      alt: 'Clutch Champion 2023',
      text: 'Clutch Champion 2023',
    },
  ]

  return (
    <div className="container container-md">
      <div className={cx(containerStyles.contacts__infoCardWrapper, 'row')}>
        <div
          className={cx(
            containerStyles.contacts__infoCardCol,
            'col-12 col-sm-6 col-lg-3',
          )}
        >
          <div className={containerStyles.contacts__infoCard}>
            <div className={containerStyles.contacts__infoCardTitle}>
              Contact us
            </div>
            <div className={containerStyles.contacts__infoCardBody}>
              <a href="mailto:hello@codica.com" className="d-block">
                hello@codica.com
              </a>
              <a href="tel:++380977789031" className="d-block">
                +380 97 778 90 31
              </a>
            </div>
          </div>
        </div>

        <div
          className={cx(
            containerStyles.contacts__infoCardCol,
            'col-12 col-sm-6 col-lg-3',
          )}
        >
          <div className={containerStyles.contacts__infoCard}>
            <div className={containerStyles.contacts__infoCardTitle}>
              Marketing cooperations
            </div>
            <div className={containerStyles.contacts__infoCardBody}>
              <a href="mailto:pr@codica.com">pr@codica.com</a>
            </div>
          </div>
        </div>

        <div
          className={cx(
            containerStyles.contacts__infoCardCol,
            'col-12 col-sm-6 col-lg-3',
          )}
        >
          <div className={containerStyles.contacts__infoCard}>
            <div className={containerStyles.contacts__infoCardTitle}>
              Representative office
            </div>
            <div className={containerStyles.contacts__infoCardBody}>
              <span className={containerStyles.contacts__br}>
                10152 Vesivärava
              </span>
              <span className={containerStyles.contacts__br}>
                50-201, Tallinn, Estonia
              </span>
            </div>
          </div>
        </div>

        <div
          className={cx(
            containerStyles.contacts__infoCardCol,
            'col-12 col-sm-6 col-lg-3',
          )}
        >
          <div className={containerStyles.contacts__infoCard}>
            <div className={containerStyles.contacts__infoCardTitle}>
              Development team
            </div>
            <div className={containerStyles.contacts__infoCardBody}>
              Remote, Ukraine
            </div>
          </div>
        </div>

        <div
          className={cx(
            containerStyles.contacts__infoCardCol,
            'col-12 col-sm-6 col-lg-3',
          )}
        >
          <div
            className={cx(
              containerStyles.contacts__infoCard,
              containerStyles.contacts__withOutBorderBottom,
            )}
          >
            <div className={containerStyles.contacts__infoCardTitle}>
              Working time
            </div>
            <div className={containerStyles.contacts__infoCardBody}>
              <span className={containerStyles.contacts__br}>
                08:00 — 20:00
              </span>
              <span className={containerStyles.contacts__br}>
                (Ukraine time zone)
              </span>
              <span className={containerStyles.contacts__br}>
                Monday — Friday
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={cx(containerStyles.contacts__awardsCardWrapper, 'row')}>
        {contactAwards.map(({ logo, text, alt }) => (
          <div
            key={alt}
            className={cx(
              containerStyles.contacts__awardsCardCol,
              'col-12 col-sm-6 col-lg-3',
            )}
          >
            <div className={containerStyles.contacts__awardsCard}>
              <div className={containerStyles.contacts__awardsCardLogo}>
                <GatsbyImage
                  image={logo as IGatsbyImageData}
                  alt={`${alt} | Codica`}
                  title={alt}
                />
              </div>
              <div className={containerStyles.contacts__awardsCardTitle}>
                {text}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ContactInfo
